// Margin //
.m-0 {
    margin: 0;
}
.m-1 {
    margin: 0.2rem;
}
.m-2 {
    margin: 0.5rem;
}
.m-3 {
    margin: 0.9rem;
}
.m-4 {
    margin: 1rem;
}
.m-5 {
    margin: 1.5rem;
}
.mt-4 {
    margin-top: 1rem;
}

.mb-4{
    margin-bottom: 1rem;
}
.mt-5 {
    margin-top: 2.5rem;
}

.mb-5{
    margin-bottom: 2.5rem;
}

.ml-5{
    margin-left: 2.5rem;
}
.mr-5{
    margin-right: 2.5rem;
}

// Padding //
.p-0 {
    padding: 0;
}
.p-4{
    padding: 1rem;
}
.pt-4{
    padding-top: 1rem;
}
.pb-4{
    padding-bottom: 1rem;
}
.pl-4{
    padding: 0 1rem 0 1rem;
}
.ptb-4{
    padding: 1rem 0 1rem 0;
}

.plr-5{
    padding: 0 1.5rem 0 1.5rem;
}
.pt-5{
    padding-top: 2.5rem;
}

.pb-5{
    padding-bottom: 2.5em;
}

.p-5{
  padding: 1.5rem;
}


.h-100{
    height: 100vh;
}


// *{
//   background: #000 !important;
//   color: #0f0 !important;
//   outline: solid #f00 1px !important; 
// }

* {
    margin: 0;
    padding: 0;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    height: 100%;
    overflow-y: scroll;
    scroll-behavior: smooth;

    body {
        width: 100%;
        min-height: 100%;
        position: relative;
        padding-bottom: 98px;
        font-size: 15px;
        background-color: $mainBg;
        color: rgba($color: $white, $alpha: 0.85);
        font-family: 'Open Sans', sans-serif;
    }
}

.top__title {
    font-size: 14px;
    font-weight: 600;
    color: rgba($color: $gold, $alpha: 0.9);
    text-align: center;
    text-transform: uppercase;

    @include md {
        text-align: start;
    }
}

.sub__title {
    color: $white;
    font-weight: 500;
    font-size: 38px;
    font-weight: 600;
    font-family: 'Playfair Display';
    margin-bottom: 40px;
    text-align: center;

    @include md {
        text-align: start;
    }
}

*::-webkit-scrollbar {
    width: 10px;
}

*::-webkit-scrollbar-track {
    background: $mainBg;
}

*::-webkit-scrollbar-thumb {
    background-color: $lightBg;
    border-radius: 3px;
    border: 0.5px solid rgba($color: $white, $alpha: 0.1);
}

.turbo-progress-bar {
    height: 3px;
    background-color: rgba($color: $gold, $alpha: 0.9)
}

.fade {
    opacity: 0;
    transform: translateY(30px);
}

.fade.in {
    opacity: 1 !important;
    transform: translateY(0) !important;
    transition: .5s cubic-bezier(.5, 0, 0, 1);
    transition-property: opacity, transform;
}

div.invalid-feedback {
    color: rgba($color: $danger, $alpha: 1.0);
    padding-bottom: 20px;
}

input.invalid-feedback,
textarea.invalid-feedback {
    border: 0.5px solid rgba($color: $danger, $alpha: 0.8) !important;
    outline: none;
}
.projects__section {
    padding-top: 100px;
    padding-bottom: 100px;
    overflow-y: hidden;
    background-image: $bgImage;

    &::-webkit-scrollbar {
        display: none;
    }

}

.project {
    display: flex;
    width: calc(100vw - 50px);
    max-width: 490px;
    padding: 0 auto;
    flex-direction: column;
    color: rgba($color: $white, $alpha: 0.85);
    font-weight: 400;
    background-color: $lightBg;
    border-bottom: 2px solid rgba($color: $gold, $alpha: .5);
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    cursor: grab;
    margin: 0 auto 25px;

    @include md {
        max-width: 470px;

        &:nth-child(1n+2) {
            margin-left: 25px;
        }
    }

    .project__img {
        border-radius: 8px;
        overflow: hidden;

        img {
            width: 100%;
            height: auto;
            border-radius: 8px;
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transition: .5s ease-in-out;
            transition: .5s ease-in-out;
        }

    }

    &:hover {
        border-bottom: 4px solid rgba($color: $gold, $alpha: 0.7);

        img {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }
    }

    .project__description {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 300px;
        padding: 30px;
        margin-bottom: 30px;

        .sub__title {
            font-size: 20px !important;
        }

        .top__title {
            font-size: 11px !important;
        }
    }

    @include md {}
}

.porject__link {
    padding: 20px 0 0;

    a {
        border: 1.5px solid $gold;
        border-radius: 2px;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        text-decoration: none;
        color: $white;
        font-family: 'Open Sans';
        font-weight: 600;
        padding: 8px 30px;
        transition: 0.5s;

        &::after {
            content: url('../../public/img/github.svg');
            position: absolute;
            opacity: 0;
            margin-left: 10px;
            transition: .4s;
        }

        &:hover {
            color: $black;
            background-color: $gold;
            padding: 8px 40px;

            &:after {
                opacity: 1;
            }
        }
    }

}

/** Flickity section */
.flickity {
    &::after {
        content: '';
    }

    @include md {
        &::after {
            content: 'flickity';
            display: none;
        }
    }
}

.flickity-viewport {
    margin-bottom: 20px;
}

.flickity-enabled:focus {
    outline: none
}

.flickity-button {
    background-color: rgba($color: $gold, $alpha: 0.85);
    border: none;
    width: 25px;
    height: 4px;
    margin: 5px;
    cursor: pointer;

    &:disabled {
        background-color: rgba($color: $gold, $alpha: 0.5);
        cursor: default;
    }

    .flickity-button-icon {
        display: none;
    }
}
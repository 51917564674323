.contact__section {
    background-color: $contactBg;
    padding-top: 100px;
    padding-bottom: 100px;
}

.social__media {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    @include md {
        flex-direction: row;

    }

    .media {
        width: 90%;
        flex: 1 1 0;
        margin-left: auto;
        margin-right: auto;
        padding: 32px;
        text-decoration: none;
        border: 0.5px solid rgba($color: $gold, $alpha: 0.8);
        border-radius: 8px;
        margin-bottom: 30px;
        color: rgba($color: $white, $alpha: 1);
        font-weight: 400;

        @include md {
            width: auto;

            &:nth-child(1n+2) {
                @include sm {
                    margin-left: 20px;
                }
            }
        }

        &:hover,
        &:focus {
            background-color: rgba($color: $gold, $alpha: 1.0);
            color: rgba($color: $black, $alpha: .8);
            transition: 0.4s !important;
            .media__link {
                color: rgba($color: $black, $alpha: 0.9);
                ;
            }

        }

        .media__name {
            font-size: 22px;
            font-weight: 600;
            padding-bottom: 5px;
            font-family: 'Playfair Display';

            img {
                width: 40px;
                float: right;
                opacity: 0.6;
            }

        }
    }

}
.media__link {
    font-size: 13px;
    color: rgba($color: $gold, $alpha: 0.9);

}
// // .container {
// //     box-sizing: border-box;
// //     margin: 0 auto;
// //     padding: 0 1rem 0 1rem;
// //     @include sm {
// //         max-width: $screen-sm-min
// //     }
// //     @include md{
// //         max-width: $screen-md-min
// //     }
// //     @include lg {
// //         max-width: $screen-lg-min
// //     }
// //     @include xl{
// //         max-width: $screen-xl-min
// //     }
// //  }
 
// //  .container-fluid {
// //     max-width: 100%;
// //     box-sizing: border-box;
// //     margin: 0% auto;
// //  }

// // .row{
// //     display: flex;
// //     flex-wrap: wrap;
// // }
// /* For desktop: */
// // .col-1 {width: 8.33%;}
// // .col-2 {width: 16.66%;}
// // .col-3 {width: 25%;}
// // .col-4 {width: 33.33%;}
// // .col-5 {width: 41.66%;}
// // .col-6 {width: 50%;}
// // .col-7 {width: 58.33%;}
// // .col-8 {width: 66.66%;}
// // .col-9 {width: 75%;}
// // .col-10 {width: 83.33%;}
// // .col-11 {width: 91.66%;}
// // .col-12 {width: 100%;}

@media only screen and (max-width: $screen-lg-min) {
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }
}

.hero, 
.header, 
.page-header, 
.section {
    @include xl{
        padding-left: calc(50vw - 570px);
        padding-right: calc(50vw - 570px);
    }
    padding-left: 20px;
    padding-right: 20px;
}


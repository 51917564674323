@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  height: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
html body {
  width: 100%;
  min-height: 100%;
  position: relative;
  padding-bottom: 98px;
  font-size: 15px;
  background-color: #1F2235;
  color: rgba(255, 255, 255, 0.85);
  font-family: "Open Sans", sans-serif;
}
.top__title {
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 197, 118, 0.9);
  text-align: center;
  text-transform: uppercase;
}
@media only screen and (min-width: 768px) {
  .top__title {
    text-align: start;
  }
}
.sub__title {
  color: #fff;
  font-weight: 500;
  font-size: 38px;
  font-weight: 600;
  font-family: "Playfair Display";
  margin-bottom: 40px;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .sub__title {
    text-align: start;
  }
}
*::-webkit-scrollbar {
  width: 10px;
}
*::-webkit-scrollbar-track {
  background: #1F2235;
}
*::-webkit-scrollbar-thumb {
  background-color: #313552;
  border-radius: 3px;
  border: 0.5px solid rgba(255, 255, 255, 0.1);
}
.turbo-progress-bar {
  height: 3px;
  background-color: rgba(255, 197, 118, 0.9);
}
.fade {
  opacity: 0;
  transform: translateY(30px);
}
.fade.in {
  opacity: 1 !important;
  transform: translateY(0) !important;
  transition: 0.5s cubic-bezier(0.5, 0, 0, 1);
  transition-property: opacity, transform;
}
div.invalid-feedback {
  color: #dc3545;
  padding-bottom: 20px;
}
input.invalid-feedback,
textarea.invalid-feedback {
  border: 0.5px solid rgba(220, 53, 69, 0.8) !important;
  outline: none;
}
.m-0 {
  margin: 0;
}
.m-1 {
  margin: 0.2rem;
}
.m-2 {
  margin: 0.5rem;
}
.m-3 {
  margin: 0.9rem;
}
.m-4 {
  margin: 1rem;
}
.m-5 {
  margin: 1.5rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mt-5 {
  margin-top: 2.5rem;
}
.mb-5 {
  margin-bottom: 2.5rem;
}
.ml-5 {
  margin-left: 2.5rem;
}
.mr-5 {
  margin-right: 2.5rem;
}
.p-0 {
  padding: 0;
}
.p-4 {
  padding: 1rem;
}
.pt-4 {
  padding-top: 1rem;
}
.pb-4 {
  padding-bottom: 1rem;
}
.pl-4 {
  padding: 0 1rem 0 1rem;
}
.ptb-4 {
  padding: 1rem 0 1rem 0;
}
.plr-5 {
  padding: 0 1.5rem 0 1.5rem;
}
.pt-5 {
  padding-top: 2.5rem;
}
.pb-5 {
  padding-bottom: 2.5em;
}
.p-5 {
  padding: 1.5rem;
}
.h-100 {
  height: 100vh;
}
@media only screen and (max-width: 941px) {
  /* For mobile phones: */
  [class*=col-] {
    width: 100%;
  }
}
.hero,
.header,
.page-header,
.section {
  padding-left: 20px;
  padding-right: 20px;
}
@media only screen and (min-width: 1181px) {
  .hero,
.header,
.page-header,
.section {
    padding-left: calc(50vw - 570px);
    padding-right: calc(50vw - 570px);
  }
}
.header {
  display: flex;
  position: absolute;
  align-items: center;
  margin: 20px auto 20px auto;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.header__logo img {
  height: 25px;
}
@media only screen and (min-width: 576px) {
  .header__logo img {
    height: 50px;
    width: auto;
    display: block;
  }
}
.header ul {
  display: flex;
  margin-left: auto;
  align-items: center;
}
.header ul li {
  list-style: none;
}
.header ul #contact {
  margin-left: 10px;
}
@media only screen and (min-width: 768px) {
  .header ul #contact {
    margin-left: 20px;
  }
}
.header ul #contact a {
  transition: background-color 0.4s ease-out;
  border: 1.5px solid #FFC576;
  border-radius: 2px;
}
.header ul #contact:hover a {
  color: rgba(0, 0, 0, 0.9);
  background-color: #ffc576;
}
.header ul #real {
  display: inline-block;
  position: relative;
}
.header ul #real ::after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 1px;
  margin-top: 13px;
  background: #FFC576;
  transition: width 0.3s;
}
.header ul #real :hover::after {
  width: 100%;
}
.header ul a {
  font-size: 12px;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  font-family: "Open Sans";
  font-weight: 600;
  padding: 8px 13px;
}
@media only screen and (min-width: 576px) {
  .header ul a {
    font-size: 14px;
    font-weight: 600;
    padding: 13px 30px;
  }
}
footer {
  background-color: #313552;
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
  padding: 20px 0px;
  position: absolute;
  width: 100%;
  bottom: 0;
}
footer .credit {
  padding: 20px;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
}
footer .credit a {
  text-decoration: none;
  color: inherit;
}
.hero {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  padding-top: 30px;
  justify-content: center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1011%26quot%3b)' fill='none'%3e%3cpath d='M199.50041192141674 67.001035951495L121.76138021035683-108.05248084365735 20.996489329133766 25.667045801415355z' fill='rgba(28%2c 83%2c 142%2c 0.4)' class='triangle-float3'%3e%3c/path%3e%3cpath d='M1241.3269732218698 433.00619898782725L1390.7835523519384 430.3974246951626 1388.1747780592739 280.9408455650939 1238.718198929205 283.54961985775856z' fill='rgba(28%2c 83%2c 142%2c 0.4)' class='triangle-float1'%3e%3c/path%3e%3cpath d='M609.5317783064347 216.83438082737592L593.9352205092432 88.80199332855737 502.72033101367066 155.07348973440028z' fill='rgba(28%2c 83%2c 142%2c 0.4)' class='triangle-float1'%3e%3c/path%3e%3cpath d='M276.604478994415 393.9126309385579L186.21569963717616 439.9680143698078 232.27108306842604 530.3567937270466 322.65986242566487 484.30141029579676z' fill='rgba(28%2c 83%2c 142%2c 0.4)' class='triangle-float3'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1011'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3cstyle%3e %40keyframes float1 %7b 0%25%7btransform: translate(0%2c 0)%7d 50%25%7btransform: translate(-10px%2c 0)%7d 100%25%7btransform: translate(0%2c 0)%7d %7d .triangle-float1 %7b animation: float1 5s infinite%3b %7d %40keyframes float2 %7b 0%25%7btransform: translate(0%2c 0)%7d 50%25%7btransform: translate(-5px%2c -5px)%7d 100%25%7btransform: translate(0%2c 0)%7d %7d .triangle-float2 %7b animation: float2 4s infinite%3b %7d %40keyframes float3 %7b 0%25%7btransform: translate(0%2c 0)%7d 50%25%7btransform: translate(0%2c -10px)%7d 100%25%7btransform: translate(0%2c 0)%7d %7d .triangle-float3 %7b animation: float3 6s infinite%3b %7d %3c/style%3e%3c/defs%3e%3c/svg%3e");
}
@media only screen and (min-width: 941px) {
  .hero {
    flex-direction: row;
    justify-content: unset;
  }
}
.hero__body {
  align-self: center;
}
.hero__body .hero__hello {
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  color: rgba(255, 197, 118, 0.9);
}
@media only screen and (min-width: 941px) {
  .hero__body .hero__hello {
    font-size: 36px;
    font-weight: 500;
    color: rgba(255, 197, 118, 0.9);
    text-align: start;
  }
}
.hero__body .hero__name {
  color: #fff;
  font-family: "Playfair Display", serif;
  font-weight: 600;
  text-align: center;
  font-size: 60px;
  margin: 8px 0 16px 0;
  line-height: 0.9em;
}
@media only screen and (min-width: 941px) {
  .hero__body .hero__name {
    font-size: 100px;
    color: #fff;
    max-width: 640px;
    line-height: 0.9em;
    text-align: start;
  }
}
.hero__body .hero__job {
  color: #fff;
  text-align: center;
  font-size: 18px;
}
@media only screen and (min-width: 941px) {
  .hero__body .hero__job {
    font-size: 32px;
    font-weight: 400;
    text-align: start;
  }
}
.hero__img {
  display: none;
}
@media only screen and (min-width: 941px) {
  .hero__img {
    display: initial;
  }
}
@media only screen and (min-width: 941px) {
  .hero__img img {
    width: 525px;
    height: 100%;
    margin-top: 8px;
  }
}
.skills__section {
  background-color: #313552;
  padding-top: 100px;
  padding-bottom: 100px;
}
.skills__items {
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media only screen and (min-width: 768px) {
  .skills__items {
    flex-direction: row;
    align-items: stretch;
  }
}
.skills__items .skills__stack {
  max-width: 500px;
  flex: 1 1 0px;
  padding: 32px;
  border: 0.5px solid rgba(255, 197, 118, 0.6);
  border-radius: 1px;
  margin-bottom: 30px;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 400;
}
.skills__items .skills__stack:hover, .skills__items .skills__stack:focus {
  border-color: #ffc576;
}
.skills__items .skills__stack:hover .skills__techno, .skills__items .skills__stack:focus .skills__techno {
  color: #FFC576;
}
@media only screen and (min-width: 768px) {
  .skills__items .skills__stack {
    margin-bottom: 0px;
  }
}
@media only screen and (min-width: 768px) and (min-width: 576px) {
  .skills__items .skills__stack:nth-child(1n+2) {
    margin-left: 20px;
  }
}
.skills__items .skills__stack img {
  width: 130px;
  height: auto;
  margin-bottom: 20px;
}
.skills__items .skills__stack .skills__techno {
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  font-family: "Playfair Display";
  margin-bottom: 20px;
}
.projects__section {
  padding-top: 100px;
  padding-bottom: 100px;
  overflow-y: hidden;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1011%26quot%3b)' fill='none'%3e%3cpath d='M199.50041192141674 67.001035951495L121.76138021035683-108.05248084365735 20.996489329133766 25.667045801415355z' fill='rgba(28%2c 83%2c 142%2c 0.4)' class='triangle-float3'%3e%3c/path%3e%3cpath d='M1241.3269732218698 433.00619898782725L1390.7835523519384 430.3974246951626 1388.1747780592739 280.9408455650939 1238.718198929205 283.54961985775856z' fill='rgba(28%2c 83%2c 142%2c 0.4)' class='triangle-float1'%3e%3c/path%3e%3cpath d='M609.5317783064347 216.83438082737592L593.9352205092432 88.80199332855737 502.72033101367066 155.07348973440028z' fill='rgba(28%2c 83%2c 142%2c 0.4)' class='triangle-float1'%3e%3c/path%3e%3cpath d='M276.604478994415 393.9126309385579L186.21569963717616 439.9680143698078 232.27108306842604 530.3567937270466 322.65986242566487 484.30141029579676z' fill='rgba(28%2c 83%2c 142%2c 0.4)' class='triangle-float3'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1011'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3cstyle%3e %40keyframes float1 %7b 0%25%7btransform: translate(0%2c 0)%7d 50%25%7btransform: translate(-10px%2c 0)%7d 100%25%7btransform: translate(0%2c 0)%7d %7d .triangle-float1 %7b animation: float1 5s infinite%3b %7d %40keyframes float2 %7b 0%25%7btransform: translate(0%2c 0)%7d 50%25%7btransform: translate(-5px%2c -5px)%7d 100%25%7btransform: translate(0%2c 0)%7d %7d .triangle-float2 %7b animation: float2 4s infinite%3b %7d %40keyframes float3 %7b 0%25%7btransform: translate(0%2c 0)%7d 50%25%7btransform: translate(0%2c -10px)%7d 100%25%7btransform: translate(0%2c 0)%7d %7d .triangle-float3 %7b animation: float3 6s infinite%3b %7d %3c/style%3e%3c/defs%3e%3c/svg%3e");
}
.projects__section::-webkit-scrollbar {
  display: none;
}
.project {
  display: flex;
  width: calc(100vw - 50px);
  max-width: 490px;
  padding: 0 auto;
  flex-direction: column;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 400;
  background-color: #313552;
  border-bottom: 2px solid rgba(255, 197, 118, 0.5);
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  cursor: grab;
  margin: 0 auto 25px;
}
@media only screen and (min-width: 768px) {
  .project {
    max-width: 470px;
  }
  .project:nth-child(1n+2) {
    margin-left: 25px;
  }
}
.project .project__img {
  border-radius: 8px;
  overflow: hidden;
}
.project .project__img img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.project:hover {
  border-bottom: 4px solid rgba(255, 197, 118, 0.7);
}
.project:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.project .project__description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 300px;
  padding: 30px;
  margin-bottom: 30px;
}
.project .project__description .sub__title {
  font-size: 20px !important;
}
.project .project__description .top__title {
  font-size: 11px !important;
}
.porject__link {
  padding: 20px 0 0;
}
.porject__link a {
  border: 1.5px solid #FFC576;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  font-family: "Open Sans";
  font-weight: 600;
  padding: 8px 30px;
  transition: 0.5s;
}
.porject__link a::after {
  content: url("github.9d8669c2.svg");
  position: absolute;
  opacity: 0;
  margin-left: 10px;
  transition: 0.4s;
}
.porject__link a:hover {
  color: #000;
  background-color: #FFC576;
  padding: 8px 40px;
}
.porject__link a:hover:after {
  opacity: 1;
}
/** Flickity section */
.flickity::after {
  content: "";
}
@media only screen and (min-width: 768px) {
  .flickity::after {
    content: "flickity";
    display: none;
  }
}
.flickity-viewport {
  margin-bottom: 20px;
}
.flickity-enabled:focus {
  outline: none;
}
.flickity-button {
  background-color: rgba(255, 197, 118, 0.85);
  border: none;
  width: 25px;
  height: 4px;
  margin: 5px;
  cursor: pointer;
}
.flickity-button:disabled {
  background-color: rgba(255, 197, 118, 0.5);
  cursor: default;
}
.flickity-button .flickity-button-icon {
  display: none;
}
.contact__section {
  background-color: #2C3049;
  padding-top: 100px;
  padding-bottom: 100px;
}
.social__media {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
@media only screen and (min-width: 768px) {
  .social__media {
    flex-direction: row;
  }
}
.social__media .media {
  width: 90%;
  flex: 1 1 0;
  margin-left: auto;
  margin-right: auto;
  padding: 32px;
  text-decoration: none;
  border: 0.5px solid rgba(255, 197, 118, 0.8);
  border-radius: 8px;
  margin-bottom: 30px;
  color: white;
  font-weight: 400;
}
@media only screen and (min-width: 768px) {
  .social__media .media {
    width: auto;
  }
}
@media only screen and (min-width: 768px) and (min-width: 576px) {
  .social__media .media:nth-child(1n+2) {
    margin-left: 20px;
  }
}
.social__media .media:hover, .social__media .media:focus {
  background-color: #ffc576;
  color: rgba(0, 0, 0, 0.8);
  transition: 0.4s !important;
}
.social__media .media:hover .media__link, .social__media .media:focus .media__link {
  color: rgba(0, 0, 0, 0.9);
}
.social__media .media .media__name {
  font-size: 22px;
  font-weight: 600;
  padding-bottom: 5px;
  font-family: "Playfair Display";
}
.social__media .media .media__name img {
  width: 40px;
  float: right;
  opacity: 0.6;
}
.media__link {
  font-size: 13px;
  color: rgba(255, 197, 118, 0.9);
}
.contact__page {
  height: 100%;
}
.contact__page .contact__card {
  margin-top: -250px;
}
.page-header {
  padding-top: 150px;
  padding-bottom: 120px;
  position: relative;
  opacity: 0.3;
}
.page-header:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.section__contact {
  padding-top: 150px;
  padding-bottom: 150px;
}
.contact__card {
  padding: 100px 50px;
  position: relative;
  background-color: #313552;
}
@media only screen and (min-width: 768px) {
  .contact__card {
    padding: 80px;
  }
}
.contact__card .contact__detail {
  color: #fff;
  margin-bottom: 50px;
  text-align: justify;
}
.contact__form {
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 30px;
}
@media only screen and (min-width: 768px) {
  .contact__form {
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 2fr 1fr;
  }
}
.contact__form .confirm__input {
  display: none;
}
.contact__form input,
.contact__form textarea {
  width: 100%;
  background-color: #313552;
  border: 0.5px solid rgba(255, 255, 255, 0.8);
  outline: none;
  padding: 15px 10px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
}
.contact__form input:focus,
.contact__form textarea:focus {
  border-color: #FFC576;
}
.contact__form input {
  margin-bottom: 30px;
}
.contact__form textarea {
  height: 100%;
  font-family: "Open Sans", sans-serif;
}
.contact__form #email {
  margin-bottom: 0 !important;
}
.contact__form .btn__submit {
  padding: 15px 60px;
  font-size: 14px;
  font-weight: 600;
  color: #FFC576;
  background-color: #313552;
  outline: none;
  border: 0.5px solid rgba(255, 197, 118, 0.8);
  border-radius: 2px;
  transition: background-color 0.4s ease-out;
  text-transform: uppercase;
  cursor: pointer;
}
.contact__form .btn__submit:disabled {
  cursor: not-allowed;
}
.contact__form .btn__submit:hover, .contact__form .btn__submit:disabled, .contact__form .btn__submit:focus {
  color: rgba(0, 0, 0, 0.8);
  background-color: #ffc576;
}
.contact__form .btn__submit:disabled {
  cursor: wait;
  color: #FFC576;
  background-color: #313552;
  outline: none;
  border: 2px ridge rgba(255, 197, 118, 0.8);
}
.toast-title {
  font-weight: bold;
}
.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}
.toast-message a,
.toast-message label {
  color: #ffffff;
}
.toast-message a:hover {
  color: #cccccc;
  text-decoration: none;
}
.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  -webkit-text-shadow: 0 1px 0 #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}
.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}
/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 12px;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}
#toast-container {
  position: fixed;
  z-index: 999999;
  /*overrides*/
}
#toast-container * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#toast-container > div {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  -moz-box-shadow: 0 0 12px #999999;
  -webkit-box-shadow: 0 0 12px #999999;
  box-shadow: 0 0 12px #999999;
  color: #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}
#toast-container > div:hover {
  -moz-box-shadow: 0 0 12px #000000;
  -webkit-box-shadow: 0 0 12px #000000;
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer;
}
#toast-container > .toast-info {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important;
}
#toast-container > .toast-error {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") !important;
}
#toast-container > .toast-success {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==") !important;
}
#toast-container > .toast-warning {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=") !important;
}
#toast-container.toast-top-center > div,
#toast-container.toast-bottom-center > div {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
#toast-container.toast-top-full-width > div,
#toast-container.toast-bottom-full-width > div {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
.toast {
  background-color: #030303;
}
.toast-success {
  background-color: #51a351;
}
.toast-error {
  background-color: #bd362f;
}
.toast-info {
  background-color: #2f96b4;
}
.toast-warning {
  background-color: #f89406;
}
.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}
/*Responsive Design*/
@media all and (max-width: 240px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container > div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
/*# sourceMappingURL=app.css.map */

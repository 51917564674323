.contact__page {
    height: 100%;

    .contact__card {
        margin-top: -250px;
    }
}

.page-header {
    padding-top: 150px;
    padding-bottom: 120px;
    position: relative;
    opacity: 0.3;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

}

.section__contact {
    padding-top: 150px;
    padding-bottom: 150px;
}

.contact__card {
    padding: 100px 50px;
    position: relative;
    background-color: $lightBg;

    @include md {
        padding: 80px;
    }

    .contact__detail {
        color: $white;
        margin-bottom: 50px;
        text-align: justify;
    }
}

.contact__form {
    display: grid;

    @include md {
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 2fr 1fr;

    }
    .confirm__input{
        display: none;
    }
    grid-column-gap: 20px;
    grid-row-gap: 30px;

    input,
    textarea {
        width: 100%;
        background-color: $lightBg;
        border: 0.5px solid rgba($color: $white, $alpha: 0.8);
        outline: none;
        padding: 15px 10px;
        font-size: 16px;
        color: rgba($color: $white, $alpha: 0.8);

        &:focus {
            border-color: $gold;

        }
        
    }

    input {
        margin-bottom: 30px;
    }

    textarea {
        height: 100%;
        font-family: 'Open Sans', sans-serif;
    }

    #email {
        margin-bottom: 0 !important;
    }

    .btn__submit {
        padding: 15px 60px;
        font-size: 14px;
        font-weight: 600;
        color: $gold;
        background-color: $lightBg;
        outline: none;
        border: 0.5px solid rgba($color: $gold, $alpha: 0.8);
        border-radius: 2px;
        transition: background-color 0.4s ease-out;
        text-transform: uppercase;
        cursor: pointer;
        &:disabled{
          cursor: not-allowed;
        }
        &:hover,
        &:disabled,
        &:focus {
          color: rgba($color: $black, $alpha: .8);
          background-color: rgba($color: $gold, $alpha: 1);
            
        }
        &:disabled{
            cursor: wait;
            color: $gold;
            background-color: $lightBg;
            outline: none;
            border: 2px ridge rgba($color: $gold, $alpha: 0.8);
        }
    }
}